<template>
  <sm-modal
    :show="showModal"
    :modal-title="modalParams.message"
    class="confirm-modal"
    @close="cancel"
  >
    <template #body>
      <div class="confirm-modal__button-group">
        <sm-button class="confirm-modal__button" @click="confirm">{{
          modalParams.leftButtonText
        }}</sm-button>
        <sm-button
          outline
          neutrall
          class="confirm-modal__button"
          @click="cancel"
          >{{ modalParams.rightButtonText }}</sm-button
        >
      </div>
    </template>
  </sm-modal>
</template>

<script>
import SmModal from '@/components/common/modals/SmModal.vue';
import SmButton from '@/components/common/forms/SmButton.vue';

export default {
  name: 'smConfirmModal',

  components: {
    SmModal,
    SmButton,
  },

  data() {
    return {
      showModal: false,
      modalParams: {
        message: null,
        leftButtonText: 'Да',
        rightButtonText: 'Нет',
      },
      resolve: null,
    };
  },

  methods: {
    open(params) {
      this.modalParams = {
        ...this.modalParams,
        ...params,
      };

      this.showModal = true;

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },

    confirm() {
      this.resolve(true);
      this.showModal = false;
    },

    cancel() {
      this.resolve(false);
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss">
.confirm-modal .modal__content {
  min-width: 540px;
}

.confirm-modal__button-group {
  display: flex;
}

.confirm-modal__button {
  &:first-of-type {
    margin-right: 15px;
  }
}
</style>
