<template>
  <button
    :class="['button', classes, colorType ? `button--${colorType}` : false]"
    :disabled="disabled || isLoading"
    @click="$emit('click')"
  >
    <!-- :type="type" -->
    <sm-spinner v-if="isLoading" class="button__loader" />
    <span class="button__content">
      <slot></slot>
    </span>
  </button>
</template>
<script>
import SmSpinner from '@/components/common/SmSpiner.vue';

export default {
  name: 'SmButton',

  components: {
    SmSpinner,
  },

  props: {
    // type: {
    //   type: String,
    //   default: 'button',
    // },

    disabled: {
      type: Boolean,
      default: false,
    },

    outline: {
      type: Boolean,
      default: false,
    },

    neutrall: {
      type: Boolean,
      default: false,
    },

    secondary: {
      type: Boolean,
      default: false,
    },

    danger: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    // success, primary, danger, error
    colorType: {
      type: String,
      default: '',
    },

    add: {
      type: Boolean,
      default: false,
    },

    success: {
      type: Boolean,
      default: false,
    },

    small: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return {
        'button--outline': this.outline,
        'button--secondary': this.secondary,
        'button--neutrall': this.neutrall,
        'button--is-loading': this.isLoading,
        'button--add': this.add,
        'button--danger': this.danger,
        'button--success': this.success,
        'button--small': this.small,
      };
    },
  },
};
</script>

<style lang="scss">
.button {
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 36px;
  padding: 0 14px;

  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  color: var(--white);

  border: none;
  border-radius: 5px;
  background-color: var(--blue);

  box-shadow: 7px 7px 20px rgb(var(--rgb-blue) / 9%);

  transition: background 0.3s;

  cursor: pointer;

  // &:hover {
  //   background-color: var(--dark-blue);
  // }

  &:disabled {
    background-color: var(--gray);
    cursor: default;
  }
}

.button--outline {
  border: {
    width: 0.5px;
    style: solid;
    color: rgba(0, 160, 227, 0.4);
  }

  background-color: transparent;
}

.button--outline:hover {
  background-color: transparent;
}

.button--neutrall {
  background-color: var(--gray);
}

.button--danger,
.button--error {
  color: var(--white);
  background-color: var(--red);
}
.button--danger:hover,
.button--error:hover {
  color: var(--white);
}

.button--success {
  color: var(--white);
  background-color: var(--green);
}

.button--neutrall.button--outline {
  color: var(--gray);

  border: 1px solid var(--gray);

  background-color: var(--white);
  box-shadow: 7px 7px 20px rgba(152, 152, 152, 0.1);
}

.button--success.button--outline {
  color: var(--green);
  border-color: var(--green);
}

.button--primary.button--outline {
  color: var(--blue);
  border-color: var(--blue);
}

.button--secondary.button--outline {
  color: var(--blue);
  border-color: var(--blue);
}

.button--secondary.button--outline:hover {
  color: var(--white);
  background-color: var(--dark-blue);
}

.button--danger.button--outline,
.button--error.button--outline {
  color: var(--red);
  border-color: var(--red);
}

.button--outline:disabled {
  &.button--neutrall,
  &.button--success,
  &.button--primary,
  &.button--danger,
  &.button--error {
    color: var(--gray);
    background-color: var(--white);
    border-color: var(--gray);
    cursor: default;
  }
}

.button--small {
  width: auto;
  height: 22px;
  font-size: 12px;
}

.button__text {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.button__loader.spinner {
  position: absolute;

  width: 20px;
  height: 20px;
}

.button__loader.spinner div {
  transform-origin: 10px 10px;
}

.button__loader.spinner div::after {
  top: 8px;
  left: 0px;
  width: 2px;
  height: 2px;

  border-radius: 50%;

  background-color: var(--white);
}

.button__content {
  display: flex;
  align-items: center;
}

.button--is-loading .button__content {
  visibility: hidden;
}

.button--add {
  font-weight: 500;
  color: var(--gray);
  width: auto;

  cursor: pointer;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  &:hover {
    background-color: transparent;
  }
}
</style>
