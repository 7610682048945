<template>
  <div ref="notification" class="notifications__container"></div>
</template>

<script>
import Vue from 'vue';
import SmNotificationMessage from '@/components/common/notification/SmNotificationMessage.vue';

export default {
  name: 'smNotification',

  mounted() {
    Vue.prototype.$notify = this.createNotification;
  },

  methods: {
    createNotification(props) {
      const notificationClass = Vue.extend(SmNotificationMessage);
      let instance = new notificationClass({
        propsData: props,
      });
      instance.$mount();
      this.$refs.notification.appendChild(instance.$el);
      setTimeout(() => {
        this.$refs.notification.removeChild(instance.$el);
      }, props.timer);
    },
  },
};
</script>

<style lang="scss">
.notifications__container {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 9999;

  max-width: 400px;
}

/* ANIMATION */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/* /ANIMATION */

.notification {
  margin-bottom: 10px;
  padding: 10px;
  width: 400px;
  /* min-width: 200px;
  max-width: 400px; */
  box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%);
}

.notification--neutral {
  color: #989898;
  background-color: #fcfcfc;
}

.notification--info {
  color: #00a0e3;
  background-color: #f6fcff;
}

.notification--success {
  color: #50ac2f;
  background-color: #f9fcf8;
}

.notification--warning {
  color: #ffbf1c;
  background-color: #fffdf8;
}

.notification--error {
  color: #ec3131;
  background-color: #fef8f8;
}

.notification__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
}

.notification__header-column {
  display: flex;
  align-items: center;
  /* flex: 0 0 auto; */
}

.notification__btn {
  cursor: pointer;
}

.notification__btn--close {
  width: 14px;
  height: 14px;
  transform: rotate(45deg);
}

.notification__btn--close::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  margin: auto;
  height: 100%;
  width: 2px;
  /* background: #989898; */
  transition: background 0.3s;
}

.notification__btn--close::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  margin: auto;
  height: 2px;
  width: 100%;
  /* background: #989898; */
  transition: background 0.3s;
}

.notification--neutral .notification__btn--close::before,
.notification--neutral .notification__btn--close::after {
  background: #989898;
}

.notification--info .notification__btn--close::before,
.notification--info .notification__btn--close::after {
  background: #00a0e3;
}

.notification--success .notification__btn--close::before,
.notification--success .notification__btn--close::after {
  background: #50ac2f;
}

.notification--warning .notification__btn--close::before,
.notification--warning .notification__btn--close::after {
  background: #ffbf1c;
}

.notification--error .notification__btn--close::before,
.notification--error .notification__btn--close::after {
  background: #ec3131;
}

.notification__text {
  font-size: 0.9rem;
  font-weight: 300;
}
</style>
