import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { API_SM_CENTER } from '@/constants/url.js';

Vue.use(VueAxios, axios);

const createAxios = (baseURL) => {
  const instance = axios.create({ baseURL });

  instance.interceptors.request.use((config) => {
    let token = localStorage.getItem('token');

    config.headers.acx = token ? token : '';
    return config;
  });

  instance.interceptors.response.use((response) => {
    if (
      response.status === 200 &&
      response.data.error &&
      !response.data.isSucceed
    ) {
      Vue.prototype.$notify({
        header: 'Ошибка',
        text: response.data.error,
        type: 'error',
        timer: 5000,
      });
    }
    return response;
  });

  return instance;
};

const apiSmCentre = createAxios(API_SM_CENTER);

export { apiSmCentre };
