const authorizedRoutes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/Index.vue'),
    meta: {
      title: 'НИЦ | Софтфон',
    },
  },
];

export default authorizedRoutes.map((route) => {
  route.meta = {
    middleware: { unauthorizedOnly: false },
    layout: 'Empty',
    ...route.meta,
  };

  return route;
});
