import Vue from 'vue';
import Router from 'vue-router';

import unauthorizedRoutes from './unauthorizedRoutes.js';
import authorizedRoutes from './authorizedRoutes.js';

Vue.use(Router);

const routes = [...unauthorizedRoutes, ...authorizedRoutes];

const router = new Router({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

const defaultTitle = 'НИЦ';

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || defaultTitle;
  });
});

export default router;
