const unauthorizedRoutes = [];

export default unauthorizedRoutes.map((route) => {
  route.meta = {
    middleware: { unauthorizedOnly: true },
    layout: 'Empty',
    ...route.meta,
  };
  return route;
});
