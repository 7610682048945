<template>
  <div class="modal-backdrop" v-show="show">
    <div class="modal">
      <div class="modal__content">
        <button class="modal__button-close" @click="closeModal">Close</button>
        <slot name="header">
          <h3 v-if="modalTitle" class="modal__title">{{ modalTitle }}</h3>
        </slot>
        <slot name="body"></slot>
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SmModal',

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    modalTitle: {
      type: String,
      default: '',
    },
  },

  watch: {
    // Remove scroll from page
    show: {
      immediate: true,
      handler: (show) => {
        if (show) {
          document.body.style.setProperty('overflow', 'hidden');
        } else {
          document.body.style.removeProperty('overflow');
        }
      },
    },
  },

  created() {
    document.addEventListener('keydown', this.escapeHandler);
  },

  destroyed() {
    document.removeEventListener('keydown', this.escapeHandler);
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },

    escapeHandler(event) {
      if (event.key === 'Escape' && this.show) {
        this.closeModal();
      }
    },
  },
};
</script>

<style lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  display: flex;
  flex-direction: column;

  background: rgba(0, 0, 0, 0.5);

  overflow-y: auto;
}

.modal {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 33.75rem;
  margin: 2rem auto;
}

.modal__content {
  position: relative;

  padding: 2.5rem 1.25rem;
  background: #ffffff;
  border-radius: 0.625rem;
  box-shadow: 0px 0px 20px rgba(0, 160, 227, 0.09);
  -webkit-box-shadow: 0px 0px 20px rgba(0, 160, 227, 0.09);
}

.modal__button-close {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;

  width: 1.25rem;
  height: 1.25rem;

  font-size: 0;

  border: none;
  background: none;
  cursor: pointer;

  transform: rotate(45deg);

  &:before,
  &:after {
    content: '';

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: block;
    margin: auto;

    background: #dadada;

    transition: background 0.3s;
  }

  &:before {
    width: 2px;
    height: 100%;
  }

  &:after {
    width: 100%;
    height: 2px;
  }

  &:hover::before,
  &:hover::after {
    background: #989898;
  }
}

.modal__title {
  margin-bottom: 1.25rem;

  font-size: 1.3rem;
  text-align: center;
}
</style>
