import api from '@/api/interactiveAccessObjects.js';

const initState = () => {
  return {
    cameraURL: {
      isLoading: false,
      data: null,
      error: null,
    },
    atsAccess: {
      isLoading: false,
      data: null,
      error: null,
    },
  };
};

let state = initState();

const resetStateHelper = () => {
  state = initState();
};

const mutations = {
  RESET_STATE: () => {
    resetStateHelper();

    localStorage.removeItem('token');
  },

  SET_TOKEN: (_, token) => {
    localStorage.setItem('token', token);
  },

  SET_BASE: (_, base) => {
    localStorage.setItem('base', base);
  },

  SET_LOADING: (state, { key, status }) => {
    state[key].isLoading = status;
  },

  SET_DATA: (state, { key, data }) => {
    state[key].data = data;
  },

  SET_ERROR: (state, { key, error }) => {
    state[key].error = error;
  },
};

const actions = {
  resetState({ commit }) {
    commit('RESET_STATE');
  },

  setToken({ commit }, token) {
    commit('SET_TOKEN', token);
  },

  setBase({ commit }, base) {
    commit('SET_BASE', base);
  },

  getCameraUrlByInteractiveAccessObjects({ commit }, params) {
    const key = 'cameraURL';
    commit('SET_LOADING', { key, status: true });

    return api
      .getCameraUrlByInteractiveAccessObjects(params)
      .then(({ data }) => {
        commit('SET_DATA', { key, data });
        return data;
      })
      .catch((error) => {
        commit('SET_ERROR', { key, error });
        throw error;
      })
      .finally(() => {
        commit('SET_LOADING', { key, status: false });
      });
  },

  getAtsAccess({ commit }, params) {
    const key = 'atsAccess';
    commit('SET_LOADING', { key, status: true });

    return api
      .getAtsAccess(params)
      .then(({ data }) => {
        commit('SET_DATA', { key, data });
        return data;
      })
      .catch((error) => {
        commit('SET_ERROR', { key, error });
        throw error;
      })
      .finally(() => {
        commit('SET_LOADING', { key, status: false });
      });
  },

  activate(_, payload) {
    return api
      .activate(payload)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default {
  name: 'interactiveAccessObjects',
  namespaced: true,
  state,
  mutations,
  actions,
};
