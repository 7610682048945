import { apiSmCentre } from '@/plugins/axios.js';

const getBaseName = () => localStorage.getItem('base');

const getCameraUrlByInteractiveAccessObjects = ({ objectID }) => {
  return apiSmCentre.get(
    `${getBaseName()}/InteractiveAccessObjects/GetCameraUrlByInteractiveAccessObjects?objectID=${objectID}`,
  );
};

const getAtsAccess = ({ atsNumber }) => {
  return apiSmCentre.get(
    `${getBaseName()}/InteractiveAccessObjects/GetAtsAccess?atsNumber=${atsNumber}`,
  );
};

const activate = (params) => {
  return apiSmCentre.post(
    `${getBaseName()}/InteractiveAccessObjects/Activate`,
    params,
  );
};

export default {
  getCameraUrlByInteractiveAccessObjects,
  getAtsAccess,
  activate,
};
