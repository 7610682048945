<template>
  <div id="app">
    <vue-extend-layouts />
    <sm-notification />
    <sm-confirm-modal ref="confirmModal" />
  </div>
</template>

<script>
import VueExtendLayouts from 'vue-extend-layout';
import SmNotification from '/src/components/common/notification/SmNotification.vue';
import SmConfirmModal from '@/components/common/modals/SmConfirmModal.vue';

export default {
  name: 'App',

  components: { VueExtendLayouts, SmConfirmModal, SmNotification },

  mounted() {
    this.$root.$confirmModal = this.$refs.confirmModal.open;
  },
};
</script>
