import Vue from 'vue';
import Vuex from 'vuex';

import interactiveAccessObjects from '@/store/modules/interactiveAccessObjects.js';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    interactiveAccessObjects,
  },
});
